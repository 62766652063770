<template>
  <div>
    <br />
    <span class="heading" style="color: #642c90">Test Evaluation</span>
    <hr style="color: #e0e0e0 !important" />
    <div class="card-panel">
      <div class="row">
        <div class="col l3 s12 assignment-filter">
          <span class="heading" style="color: #686868">Course</span>
          <select
            class="browser-default dropd"
            @change="onCourseChange($event)"
            v-model="selectCourse"
          >
            <option selected>-- SELECT --</option>
            <option :value="data.courseId" v-for="data in Courses" :key="data.affiliationCourseId">
              {{ data.courseName }}
            </option>
          </select>
        </div>
        <div class="col l3 s12 assignment-filter">
          <span class="heading" style="color: #686868">Batch</span>
          <VueMultiselect
            class="dropd"
            v-model="AffiliationBatch"
            :options="listOfBatchesForSelectedCourse"
            placeholder="Select one"
            @update:model-value="updateSelectedBatch"
            label="BatchName"
            track-by="BatchId"
            :multiple="true"
            :limit="1"
            :close-on-select="false"
            @tag="BatchTag"
          >
            <template v-slot:beforeList>
              <div class="row" style="margin-top: 5px">
                <a class="multiselectBtn waves-effect waves-light btn" @click="selectBatchNone"
                  ><span style="display: flex; justify-content: center">None</span></a
                >&nbsp;
                <a
                  class="multiselectBtn waves-effect waves-light btn"
                  @click="selectBatchAll"
                  @update:model-value="updateSelectedBatch"
                  ><span>All </span></a
                >
              </div>
            </template>
          </VueMultiselect>
        </div>
        <div class="col l3 s12 assignment-filter">
          <span class="heading" style="color: #686868">Subject</span>
          <select
            class="browser-default dropd"
            @change="onSubjectChange($event)"
            v-model="selectSubject"
          >
            <option selected>-- SELECT --</option>
            <option
              :value="subject.subjectId"
              v-for="subject in listOfSubjectsForSelectedCourse"
              :key="subject.subjectId"
            >
              {{ subject.subjectName }}
            </option>
          </select>
        </div>
        <div class="col l3 s12 assignment-filter">
          <button class="batch-button" @click="searchTest()">Search Test</button>
        </div>
      </div>
    </div>
    <div class="content-header" v-if="showData === true">
      <div class="video-tabs">
        <li class="video-tab col s12 m3">
          <a :class="{ active: selectedTab === 1 }" class="video-selector" @click="selectPendingTab"
            >Pending</a
          >
        </li>
        <li class="video-tab col s12 m3">
          <a
            :class="{ active: selectedTab === 2 }"
            class="video-selector"
            @click="selectCompleteTab"
            >Completed</a
          >
        </li>
      </div>
    </div>
    <div class="row" v-if="selectedTab === 1">
      <div
        v-if="mainGallery"
        style="display: flex; flex-wrap: wrap; justify-content: space-between"
      >
        <div
          class="test-card card-panel"
          v-for="(data, index) in pendingTest"
          :key="index"
          :value="data"
        >
          <div style="display: flex; justify-content: space-between">
            <div style="display: flex; flex-direction: column">
              <span class="test-course">{{ data.CourseName }}</span>
              <strong class="test-title">{{
                data.Title.length > 25 ? data.Title.slice(0, 25) : data.Title
              }}</strong>
              <span class="test-difficulty">{{ data.Difficulty }}</span>
            </div>
            <div style="display: flex; flex-direction: column">
              <span class="test-course" style="justify-content: center">{{ data.Pending }}</span>
              <span class="test-course" style="justify-content: center">Questions</span>
              <span class="test-course" style="justify-content: center">Left</span>
            </div>
          </div>
          <hr style="border: 0.5px solid #e0e0e0; margin-bottom: 5px" />
          <div style="display: flex; justify-content: space-between">
            <div style="display: flex; justify-content: space-between; width: 200px">
              <div class="time">
                <img
                  style="height: 18px; margin-right: 0 !important"
                  :src="require(`../assets/time12.svg`)"
                />&nbsp;
                <span>{{ formatTime(data.StartTime) }}</span>
              </div>
              <div class="date">
                <img style="height: 18px" :src="require(`../assets/calendar12.svg`)" />&nbsp;
                <span>{{ formatDate(data.StartTime) }}</span>
              </div>
            </div>
            <button class="evaluate-button" @click="Evaluate(data)">
              <span class="evaluate-text">Evaluate</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="selectedTab === 2">
      <div
        v-if="mainGallery"
        style="display: flex; flex-wrap: wrap; justify-content: space-between"
      >
        <div
          class="test-card card-panel"
          v-for="(data, index) in completeTest"
          :key="index"
          :value="data"
        >
          <div style="display: flex; justify-content: space-between">
            <div style="display: flex; flex-direction: column">
              <span class="test-course">{{ data.CourseName }}</span>
              <strong class="test-title">{{
                data.Title.length > 25 ? data.Title.slice(0, 25) : data.Title
              }}</strong>
              <span class="test-difficulty">{{ data.Difficulty }}</span>
            </div>
            <div style="display: flex; flex-direction: column">
              <span class="test-course" style="justify-content: center">{{ data.Completed }}</span>
              <span class="test-course" style="justify-content: center">Questions</span>
              <span class="test-course" style="justify-content: center">Marked</span>
            </div>
          </div>
          <hr style="border: 0.5px solid #e0e0e0; margin-bottom: 5px" />
          <div style="display: flex; justify-content: space-between">
            <div style="display: flex; justify-content: space-between; width: 200px">
              <div class="time">
                <img style="height: 18px" :src="require(`../assets/time12.svg`)" />&nbsp;
                <span>{{ formatTime(data.StartTime) }}</span>
              </div>
              <div class="date">
                <img style="height: 18px" :src="require(`../assets/calendar12.svg`)" />&nbsp;
                <span>{{ formatDate(data.StartTime) }}</span>
              </div>
            </div>
            <button class="evaluated-button" @click="Evaluate(data)">
              <span class="evaluate-text">Evaluated</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueMultiselect from "vue-multiselect";
import moment from "moment";
// import Vue from "vue";
import Mobileapi from "../Mobileapi";
import Api from "../Api";

// Vue.filter("formatDate", (value) => {
//   if (value) {
//     return moment(String(value)).format("DD/MM/YYYY");
//   }
//   return value;
// });

// Vue.filter("formatTime", (value) => {
//   if (value) {
//     return moment(String(value)).utcOffset("-00:00").format(" hh:mm a");
//   }
//   return value;
// });
export default {
  data() {
    return {
      testList: [],
      Courses: [],
      completeTest: [],
      pendingTest: [],
      notStartedTest: [],
      listOfBatchesForSelectedCourse: [],
      listOfSubjectsForSelectedCourse: [],
      selectCourse: null,
      AffiliationBatch: [],
      selectSubject: null,
      selectedTab: 1,
      mainGallery: true,
      gridList: false,
      showData: false,
      batchesId: "",
    };
  },
  activated() {
    const search = this.$route.params.search;
    const user = this.$store.getters.user;
    Api.getCourses({}, (course) => {
      this.Courses = course.data;
    });
    Api.getBatches(user.AffiliationId, (batch) => {
      this.courseBatchList = batch.data;
    });
    if (search === true) {
      this.searchTest();
    }
  },
  methods: {
    formatDate(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY");
      }
      return value;
    },
    formatTime(value) {
      if (value) {
        return moment(String(value)).utcOffset("-00:00").format(" hh:mm a");
      }
      return value;
    },
    onCourseChange(event) {
      this.selectCourse = Number(event.target.value);
      this.listOfBatchesForSelectedCourse = this.courseBatchList.filter(
        (a) => a.CourseId === this.selectCourse,
      );
      this.selectedBatches = [];
      this.AffiliationBatch = null;
      for (let i = 0; i < this.Courses.length; i += 1) {
        if (this.Courses[i].courseId === Number(event.target.value)) {
          this.listOfSubjectsForSelectedCourse = this.Courses[i].subjectList;
          this.AffiliationCourseId = Number(this.Courses[i].affiliationCourseId);
          break;
        }
      }
    },
    onSubjectChange(event) {
      this.selectSubject = Number(event.target.value);
    },
    BatchTag(newTag) {
      const tag = {
        BatchName: newTag,
        BatchId: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
      };
      this.listOfBatchesForSelectedCourse.push(tag);
      this.AffiliationBatch.push(tag);
    },
    selectBatchNone() {
      this.AffiliationBatch = [];
    },
    selectBatchAll() {
      this.AffiliationBatch = this.listOfBatchesForSelectedCourse;
      const batch = this.AffiliationBatch.map((a) => a.BatchId);
      // console.log(batch);
      this.batchId = String(batch);
      this.batchesId = this.batchId;
      // console.log(this.batchesId);
      this.SubjectValidation = false;
    },
    updateSelectedBatch(value) {
      if (this.AffiliationBatch.length >= 0) {
        this.selectedBatches = [];
      }
      value.forEach((resource) => {
        this.selectedBatches.push(resource);
      });
      const batch = this.selectedBatches.map((a) => a.BatchId);
      this.batchId = String(batch);
      this.batchesId = this.batchId;
      this.SubjectValidation = false;
    },
    searchTest() {
      this.$store.dispatch("showLoader", true);
      Mobileapi.getSubjectiveInstituteTest(
        { CourseId: this.selectCourse, Batches: this.batchesId, SubjectId: this.selectSubject },
        (response) => {
          this.testList = response.data;
          this.pendingTest = this.testList.filter((a) => a.Pending !== 0);
          this.completeTest = this.testList.filter((a) => a.Completed !== 0);
          this.$store.dispatch("showLoader", false);
        },
      );
      this.showData = true;
    },
    selectPendingTab() {
      this.selectedTab = 1;
      this.pendingTest = this.testList.filter((a) => a.Pending !== 0);
    },
    selectCompleteTab() {
      this.selectedTab = 2;
      this.completeTest = this.testList.filter((a) => a.Completed !== 0);
    },
    Evaluate(data) {
      this.$router.push({
        name: "Subjective Evaluation",
        params: {
          InstituteTestId: Number(data.InstituteTestId),
        },
      });
    },
  },
  components: {
    VueMultiselect,
  },
};
</script>

<style scoped>
.heading {
  margin-top: 20px;
  /* font-family: Open Sans;
    font-style: normal; */
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  /* identical to box height */
  color: #333333;
  width: 247px;
  height: 25px;
}
.browser-default {
  border-radius: 5px;
  height: 40px;
  border: 1px solid #e3e3e3;
}
.multiselectBtn {
  width: 90px;
  height: 35px;
  background: linear-gradient(90.2deg, #ff9421 0.16%, #fbad26 98.41%);
}
.batch-button {
  width: 194px;
  height: 35px;
  background: linear-gradient(90.2deg, #ff9421 0.16%, #fbad26 98.41%);
  border-radius: 5px;
  cursor: pointer;
  border: none;
  color: #ffffff;
  margin-top: 25px;
}
.content-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.video-tabs {
  background-color: transparent;
  width: fit-content;
  margin: 0;

  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  margin-top: 17px;
  list-style: none;
}
.video-tabs .video-tab {
  height: auto;
  text-align: left;
  line-height: unset;
  line-height: 30px;
}
.video-tabs .video-tab a {
  margin: 0px 40px 0 0;
  padding: 0;
  color: rgba(29, 30, 30, 0.5);
  text-align: left;
  /* position: relative; */
  font-weight: normal;
}
.video-tabs .video-tab a.active {
  color: #000;
}

.video-tabs .video-tab a.active::after {
  background: #584f52;
  width: 10px;
}

.video-tabs .video-tab a::after {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  width: 0;
  height: 1px;
  z-index: 9;
  background: rgba(29, 30, 30, 0.5);
  transition: all 0.3s ease;
}

.video-tabs .video-tab a:hover::after {
  width: 10px;
}

.content-header .grid-list-icon {
  width: fit-content;
  position: relative;
  top: 11px;
  left: -15px;
}
.content-header .grid-list-icon .material-icons {
  cursor: pointer;
}
.test-course {
  font-size: 15px;
  font-weight: 650;
  line-height: 14px;
  display: flex;
  align-items: center;
  letter-spacing: -0.139031px;
  text-transform: capitalize;
  /* Primary */
  color: #7d4180;
  margin-bottom: 5px;
}
.test-title {
  font-size: 16px;
  line-height: 125%;
  /* identical to box height, or 18px */
  display: flex;
  align-items: center;
  color: #222222;
  margin-bottom: 5px;
}
.test-difficulty {
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */
  letter-spacing: -0.11917px;
  /* Text Grey */
  color: #858585;
}
.time {
  display: flex;
  flex-direction: row;
}
.date {
  display: flex;
  flex-direction: row;
}
.evaluate-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background: #7d4180;
  border-radius: 4px;
  height: 35px;
  border: none;
}
.evaluate-text {
  color: white;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height */

  letter-spacing: -0.11917px;
}
.test-card {
  width: 360px !important;
}
.evaluated-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background: #f9a825;
  border-radius: 4px;
  border: none;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
